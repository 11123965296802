.content-dashboard {

  margin-top: 40px;

  .card {


    .icon-big {
      font-size: 3em;
      min-height: 64px;

      i {
        line-height: 59px;
      }
    }

    .card-category,
    label {
      font-weight: 400;
      color: #9A9A9A;
      margin-bottom: 0px;
      margin-top: 5px;
    }

    .card-title {
      margin: 0;
      color: #333333;
      font-weight: 300;
      font-size: 1.75rem;
    }


    &.table-card {
      .card-title {
        margin: 0 0 10px;
        padding-bottom: 10px;
      }
    }

    &.top-card {
      .card-body {
        padding: 1.5rem 1.25rem;
      }
    }
  }
}


.call-log-icon {
  > i.fa-phone-alt {
    font-size: 20px;
    left: -5px;
    color: #999;
  }

  > i.fa-arrow-left {
    transform: rotate(135deg);
    /* right: -2px; */
    left: 1px;
    top: -6px;
    font-size: 12px;
    font-weight: 500;
  }

  &.outgoing-call {
    > i.fa-arrow-left {
      color: darkgreen;
    }
  }

  &.incoming-call {
    > i.fa-arrow-left {
      transform: rotate(-45deg);
      color: orangered;
    }
  }
}

.dashboard-contact {
  font-size: 16px;
  margin-left: 15px;
}
