/*
  stc theme: #56afb3
 */
.monitor-save-state {
  display: flex;
  padding: 5px;
  margin-bottom: 5px;
  align-items: center;
}

.monitor-unsaved {
  float: left;
  color: #e70404;
  font-style: italic;
  width: 50%;
}

.monitor-save-button {
  width: 50%;
  text-align: right;
}

.monitor-form-enabled {
  padding: 10px;
  background: #f2f8f6;
  border-radius: 10px;
  border: solid #75b9b4 1px;
}

.monitor-form-disabled {
  padding: 10px;
  background: #fafafa;
  border-radius: 10px;
  border: solid #f3f3f3 1px;
}

.monitor-inputs {
  padding-top: 8px;
  padding-bottom: 8px;
}

.monitor-inputs > input {
  margin-top: 10px;
  margin-right: 10px;
}

.monitor-note {
  color: #8f794f;
  margin-top: 5px;
  padding: 3px 3px 3px 10px;
  background: #ffec9f;
  border: #8f794f solid 1px;
  border-radius: 5px;
}