/* here you can put your own css to customize and override the theme */
.table-middle > thead > tr > th,
.table-middle > thead > tr > td,
.table-middle > tbody > tr > th,
.table-middle > tbody > tr > td,
.table-middle > tfoot > tr > th,
.table-middle > tfoot > tr > td {
  vertical-align: middle;
}

/* Custom modal show effect */
/*.modal.fade .modal-dialog {
    transform: scale(0);
    opacity: 0;
    -webkit-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear;
}

.modal.fade.in .modal-dialog {
    opacity: 1;
    transform: scale(1);
}*/

.position-relative {
  position: relative;
}


.btn-pagination {
  color: #333;
  background-color: #fff;
  border-color: #ececec;
}

.btn-pagination:hover,
.btn-pagination:focus,
.btn-pagination:active {
  color: #333;
  background-color: #ececec;
  border-color: #ececec;
}


/** Import products pagination **/
.page-selector-addon,
.page-selector-input {
  float: left;
}

.page-selector-addon {
  padding:6px 12px;
}
.page-selector {
  width: 80px;
}
.page-selector .Select-control {
  height: 34px;
}
.page-selector .Select-input,
.page-selector .Select-placeholder, :not(.Select--multi) > .Select-control .Select-value {
  line-height: 32px;
  color: #555555;
}


.modal.fade.in {
  display: block;
}

.animated {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both
}

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0)
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px)
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px)
  }
}

@-moz-keyframes shake {
  0%, 100% {
    -moz-transform: translateX(0)
  }
  10%, 30%, 50%, 70%, 90% {
    -moz-transform: translateX(-10px)
  }
  20%, 40%, 60%, 80% {
    -moz-transform: translateX(10px)
  }
}

@-o-keyframes shake {
  0%, 100% {
    -o-transform: translateX(0)
  }
  10%, 30%, 50%, 70%, 90% {
    -o-transform: translateX(-10px)
  }
  20%, 40%, 60%, 80% {
    -o-transform: translateX(10px)
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0)
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px)
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px)
  }
}

.shake {
  -webkit-animation-name: shake;
  -moz-animation-name: shake;
  -o-animation-name: shake;
  animation-name: shake
}

/*.modal {
    display: block
}*/

.modal-content > .close {
  position: absolute;
  top: 10px;
  right: 10px
}

@media (min-width: 768px) {
  .modal-sm > .modal-dialog {
    width: 300px
  }
}

@media (min-width: 992px) {
  .modal-lg > .modal-dialog {
    width: 900px
  }
}


.loading-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  top: 0;
  z-index: 1;
  text-align: center;
  padding-top: 30px;
  border-radius: 0 0 5px 5px;
  font-size: 18px;
}


/** Fluid table **/
$break-large: 1200px;
.fluid-table {

  margin-bottom: 20px;
  table-layout: fixed;

  @media (min-width: $break-large) {
    width: auto;
    max-width: none;
    table-layout: auto;
  }

  .fluid-thead {
    border-bottom: 1px solid #d3d3d3;

    > tr > td {
      border-bottom: 0;
    }
  }

  td,
  th {
    padding: 8px;
    position: relative;
    color: #232d3f;
  }

  th {
    text-transform: uppercase;
    font-weight: 600;
    background: #e7ecf1;

    vertical-align: bottom !important;
  }

  .fluid-body {
    td {
      border-right: 0;
      padding: 8px;
      vertical-align: middle;
      font-size: 13px;
    }

    .st-product-row td {
      &:first-child {
        padding-left: 25px;
      }
    }
  }
}

.input-xs {
  height: 25px;
  padding: 3px 10px;
  font-size: 12px;
  line-height: 1.2;
  border-radius: 3px;
}

.loginContainer {
  .page-header {
    margin: 0 0 2.5rem;
    border-bottom: 0;
  }

  .page-footer {
    text-align: center;
    font-size: 13px;
    margin-top: 25px;

    > div {
      margin-bottom: 5px;
    }
  }

  .login-submit {
    min-width: 160px;
  }
}

.row-actions, .rowActions {
  .dropdown-toggle {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.row-actions.actions-short {
  text-align: right;
  position: relative;
  width: 150px;
  float: right;

  > a {
    display: inline-block;
    min-width: 20px;
    font-size: 16px;
    text-align: center;
  }

  > div.dropdown {
    display: inline-block;
  }

  > .loading {

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    background: rgba(255,255,255,1);
    z-index: 999;
  }
}

.form-file-dropzone {
  padding: 6px 10px 0;
  border: 1px solid #ccc;
  background: #f2f2f2;
  width: 250px;
  text-align: center;
  font-size: 14px;
  color: #717171;
  font-style: italic;
  cursor: pointer;
  margin-top: 10px;
}

.logo-preview {
  height: 48px;
  width: auto;
  margin-top: 5px;
  max-width: 250px;
  margin: 5px;
}

.logo-preview img {
  /*width: 100%;*/
  height: 100%;
}

.sidebar-header img {
  height: 40px;
  width: auto;
  max-width: 250px;
  margin: 5px 10px;
}

.sidebar-header>a {
  line-height: 58px;
  max-width: 250px;
  overflow: hidden;
  height: 58px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  text-align: center;
}

.main-loading {
  position: absolute;
  top: 40%;
  text-align: center;
  left: 0;
  right: 0;
  font-size: 26px;

  > i {
    font-size: 26px;
    color: #7d7d7d;
  }

  > span {
    display: block;
    margin: 15px;
    /* font-family: "Roboto"; */
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    color: #5d5d5d;
  }
}

.login-logo span {
  /*word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  display: block;*/
  display: inline-block;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 24px;
  font-weight: 400;
  margin-top: 10px;
}
.login-logo img {
  height: 50px;
  width: auto;
  /* max-width: 250px; */
  margin: 0 15px 0 0;
  display: inline-block;
  /* float: left; */
  max-width: 250px;

}

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
li.list-group-item {
  z-index: 1100;
}

li.list-group-item {
  padding: 0;
  @extend .clearfix;

  > div {
    /*display: block;
    float: left;*/
    padding: 10px 15px;

    &.column-name {
      padding-left: 0;
    }

    &.move-handler {
      padding: 7px 12px;
      text-align: center;
      font-size: 20px;

      i {
        cursor: pointer;
        color: #bbbbbb;
      }
    }
  }
}

.modal-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255,255,255,0.7);
  /* background: #000; */
  z-index: 2;
  text-align: center;

  >p {
    top: 45%;
    position: absolute;
    left: 0;
    right: 0;
  }
}

/*.sidebar-nav > li {
  border-bottom: 1px solid #2b2b2b;
}*/
.content {
  border-radius: 0.25rem;

  .tab-content {
    background: #fff;
  }

  .tab-pane {
    padding: 20px;
    border: 1px solid #dddddd;
    border-top: 0;
  }


  .nav-tabs {
    .nav-link {
      border-bottom-color: transparent;
      padding: 0.8em 1.2em;
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      background-color: #fff;
      border: 1px solid #dddddd;
      border-bottom-color: transparent;
      padding: 0.8em 1.2em;
    }
  }
}

#toast-container {
  > div {
    padding: 15px;
    opacity: .8;
    max-width: 350px;
    min-width: 300px;
    width: auto;
  }

  &.toast-top-center {

    > div {
      width: 100%;
      border-radius: 0;
      text-align: center;
       background-image: none;
      padding: 18px 15px 15px;
      min-height: 58px;
    }
  }

  >.sc-toast {
    background-image: none !important;
  }

  >div {
    margin-bottom: 0;
    box-shadow: none !important;
    opacity: 1;

    &:hover {
      box-shadow: 0 -1px 7px #000;
    }
  }

  .toast-message {
    font-size: 13px;
    font-weight: 400;
    /*font-family: "Open Sans", sans-serif;*/
  }

  .toast-close-button {
    /*font-size: 30px;
    top: -6px;*/
    line-height: 1;
    font-weight: 300;
  }
}

/*.toast {
  background-color: #030303;
}
.toast-success {
  color: #696969 !important;
  background-color: #fff;
  border: 1px solid #dddddd;
  !*border-color: #51a351;*!
  border-radius: 0.25rem;

  .toast-message:before{
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    margin-right: 10px;

    content: "\f00c";
    color: #51a351;
  }
}
.toast-error {
  background-color: #bd362f;
}
.toast-info {
  background-color: #2f96b4;
}
.toast-warning {
  background-color: #f89406;
}*/

.col-with-dropdown {
  overflow: inherit !important;
}



.voip-status {
  display: block;
  margin: auto;
  vertical-align: middle;
  font-size: 18px;
}

/*
.voip-online {
  background: #3fb61a;
}

.voip-offline {
  background: red;
  opacity: 0.3;
}*/

.table.table-allowed-listeners thead th {
  border-top: none;
  font-weight: 400;
  border-bottom-width: 1px;
}


.page-loading-container {
  position: absolute;
  left: $sidebar-width;
  right: 0;
  text-align: center;
  top: 150px;
  color: #8a8a8a;
}

.password-count {
  bottom: 16px;
  right: 10px;
  font-size: 1rem;
}


// Password strength meter color for the different levels
$strength-colors: (darkred, orangered, orange, yellowgreen, green);

// Gap width between strength meter bars
$strength-gap: 6px;

.form-hint {
  font-size: 0.8rem;
  line-height: 1.4;
  margin: -5px auto 5px;
  color: #999;

  &.error {
    color: #C00;
    font-size: 0.8rem;
  }
}

.strength-meter {
  position: relative;
  height: 3px;
  background: #DDD;
  margin: 7px 0;
  border-radius: 2px;

  // Dynamically create the gap effect
  &:before,
  &:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: #FFF;
    border-style: solid;
    border-width: 0 $strength-gap 0;
    position: absolute;
    width: calc(20% + #{$strength-gap});
    z-index: 10;
  }

  // Dynamically create the gap effect
  &:before {
    left: calc(20% - #{($strength-gap / 2)});
  }

  // Dynamically create the gap effect
  &:after {
    right: calc(20% - #{($strength-gap / 2)});
  }
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;

  // Dynamically generate strength meter color styles
  @for $i from 1 through 5 {
    &[data-strength='#{$i - 1}'] {
      width: (20% * $i);
      background: nth($strength-colors, $i);
    }
  }
}

/** Pagination **/
.pagination-top {
  margin-bottom: 10px;


}

.rows-count {
  float: right;
  margin: 3px 15px !important;
  line-height: 1;
}

/*
.pagination-bottom .rows-count {
  display: none;
}*/

.btn-orange {
  @include button-variant($orange, $orange);
  color: white;
}

@include text-emphasis-variant(".text-orange", $orange);

@include bg-variant(".bg-orange", $orange);
