.page-settings {
  &.content {
    padding: 0;
    background: transparent;
    border: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  fieldset {
    max-width: 760px;
  }

  #system-settings {

    .tab-content {
      background: #fff;
    }

    .tab-pane {
      padding: 20px;
      border: 1px solid #dddddd;
      border-top: 0;
    }
  }
}
