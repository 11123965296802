.l-web-listener {
  .login-submit {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }

  #page-content-wrapper {
    padding: 0;
    display: flex;
    justify-content: center;
    height: 100vh;
  }

  .listener-container {
    flex: 1;
    max-width: 1024px;
    margin: 10px 0px 0px 0px;
    height: 90vh;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.1),0 2px 5px 0 rgba(0,0,0,.15);
    border-radius: 8px;
    overflow: hidden;
  }

  .listener-sidebar {
    box-shadow: -19px 0 12px 12px #676767;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .remotes-heading {
    color: #ffd7b6;
  }

  .remotes-list {
    list-style: none;
    margin: 10px 0;
    padding: 0;
    flex-grow: 1;

    li {
      display: flex;
      justify-content: space-between;
      padding: 7px 20px;
      font-size: 16px;
      font-weight: 300;

      .current {
        font-weight: 500;
        color: $orange;
      }
    }
  }

  .copyright-note {
    padding: 10px;
    text-align: center;
    font-size: 12px;
  }

  .stream-container {
    height: calc(100% - 218px);
  }

  .video-element {
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    width: 100%;
  }

  .video-element-hidden {
    display: none;
  }

  .stream-client-status {
    .loading-overlay {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 26px;
      border-radius: 0;
      padding-top: 0;
      color: #bababa;
      background: transparent;
    }
  }

  .stream-status-message {
    margin-top: 2px;
    height: 22px;
  }

  .stream-video-controls {
    margin: 20px auto;
    button {
      height: 62px;
      width: 62px;
      border-radius: 50%;
      font-size: 22px;

      &.btn-light {
        background-color: #ececec;
        border-color: #ececec;
        box-shadow: none !important;
      }

      .fa-stack {
        width: 2em;
      }

      .fa-arrows-alt-h {
        margin-left: 0.6rem;
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;

      button {
        pointer-events: none;
      }
    }
  }
}
