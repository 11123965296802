.audit-report {
  &.ReactTable {
    .rt-tbody .rt-td {
      padding: 6px 8px;
      white-space: normal;
      font-size: 13px;

      &.row-actions {

        padding: 1px 8px 0;
      }
    }

    .rt-tr.-even {
      background: rgba(0, 0, 0, 0.05);
    }

  }
}