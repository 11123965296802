#page-wrapper {
  padding-left: $sidebar-width;
  transition: all 0.4s ease 0s;
}

#sidebar-wrapper {
  margin-left: -$sidebar-width;
  top: 0;
  left: $sidebar-width;
  width: $sidebar-width;
  /*background: #000;*/
  /*background: #2b7169;*/
  /*background: $primary;*/
  background: $menu-background;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  z-index: 1000;
  transition: all 0.4s ease 0s;
  box-shadow: 0 0 3px 1px #bfbfbf;
}

#page-wrapper.active {
  padding-left: 0;
}

#page-wrapper.active #sidebar-wrapper {
  left: 0;
}

#page-content-wrapper {
  width: 100%;
  padding: 20px;
  margin-top: 0;
}

.sidebar-header > a {
  padding: 0;
  font-size: 20px;
  /*color: #fff;*/
  display: block;
  text-decoration: none;
  /*background: hsla(0,0%,100%,.2);*/
  /*background: #1e524c;*/
  background: $menu-header-background;
  color: $menu-text-color;
  /*color: #ffffff;*/
  /*box-shadow: inset 0 0px 6px #1c4a45;*/
}

.sidebar-header > a > span {
  display: block;
   /*text-align: center;*/
  font-family: "Open Sans";
  padding-left: 15px;
  /*padding: 15px 10px;*/
}

.sidebar-nav {
  /*position: absolute;
  top: 0;*/
  width: $sidebar-width;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 15px;

  li {
    padding-left: 0;
    flex-grow: 1;

    a {
      /*color: $primary;*/
      color: $menu-text-color;
      display: block;
      text-decoration: none;
      padding: 12px 20px;
      font-size: 15px;
      /* line-height: 30px; */

      > i {
        font-size: 18px;
      }

      span:before {
        position: absolute;
        left: 0;
        color: #41484c;
        text-align: center;
        width: 20px;
        line-height: 18px;
      }
    }
  }
}

.sidebar-nav li a:hover,
.sidebar-nav li.active {
  color: #fff;
  background: rgba(255,255,255,0.2);
  text-decoration: none;
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
  color: #fff;
  background: rgba(255,255,255,0.2);
  outline: 0;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  line-height: 60px;
  font-size: 18px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}



.content-header {
  height: 65px;
  line-height: 65px;
}

.content-header h1 {
  margin: 0;
  margin-left: 20px;
  line-height: 65px;
  display: inline-block;
}

#menu-toggle {
  text-decoration: none;
}

.btn-menu {
  color: #000;
}

.inset {
  padding: 20px;
}

@media (max-width:767px) {

  #wrapper {
    padding-left: 0;
  }

  #sidebar-wrapper {
    left: 50px;
  }

  #wrapper.active {
    position: relative;
    left: $sidebar-width;
  }

  #wrapper.active #sidebar-wrapper {
    left: $sidebar-width;
    width: $sidebar-width;
    transition: all 0.4s ease 0s;
  }

  #menu-toggle {
    display: inline-block;
  }

  .inset {
    padding: 15px;
  }

}

.user-nav-wrapper {
  margin: -20px -20px 20px;
  padding: 7px 40px;
  /*border-bottom: 1px solid #d4d4d4;*/
  /*background: #fff;*/
  /*box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);*/
}

.user-nav {
  padding: 0;
  margin-right: 0;
  margin-bottom: 0;
  text-align: right;
}

.user-nav li {
  display: inline-block;
}

.user-nav li a {
  display: block;
  padding: 10px;
  font-size: 16px;
  color: #333;
}




ul.sidebar-submenu {

  list-style: none;
  padding-left: 0;
  /*background: #232323;*/
  /*background: $primary;*/
  /*box-shadow: inset 0 0px 6px #1c4a45;*/

  > li {
    > a {
      font-weight: 300;
      padding: 5px 55px;
      margin-left: 0;
      font-size: 14px;
      color: $menu-text-color;
    }
  }
}
