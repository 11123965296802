html {
  font-size: 14px;
}



.page-content .content {
  background: #fff;
  padding: 15px 15px 15px;

  /*box-shadow: 0 0 3px 1px #dedede;
  -moz-box-shadow: 0 0 3px 1px #dedede;
  -wekbit-box-shadow: 0 0 3px 1px #dedede;*/
  border: 1px solid #dddddd;
}

.loginContainer {
  background: #fff;
  padding: 15px 15px 15px;

  /*box-shadow: 0 0 3px 1px #dedede;
  -moz-box-shadow: 0 0 3px 1px #dedede;
  -wekbit-box-shadow: 0 0 3px 1px #dedede;*/
  border: 1px solid #dddddd;
}


.page-content h1 {
  margin-top: 0;
  font-size: 34px;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

#toast-container {

}

#toast-container>div {
  opacity: 1;
  -moz-opacity: 1;
}


.welcomeText {
  font-size: 20px;
  color: #7F7F7F;
}

.page-loading {
  padding: 5px 15px;
  /*text-align: center;*/
}

.loginContainer {
  max-width: 550px !important;
  margin-top: 15vh;
}

.blockingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.5);
  z-index: 99;
  border-radius: 6px;
}

.blockingOverlay .loader {
  position: absolute;
  top: 45%;
  left: 48%;
}

.blockingOverlay .loader > i {
  font-size: 36px;
}

.wellLogin {
  position: relative;
}

input.form-control:not(.input-sm):not(.input-lg),
select.form-control:not(.input-sm):not(.input-lg) {
  height: 39px;
  padding: 7px 10px;
  font-size: 14px;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 9px;
    font-size: 14px;
  }
}



.sticky-outer-wrapper.page-heading.active .sticky-inner-wrapper {
  background: #ffffff;
}

.sticky-outer-wrapper.page-heading h1 {
  margin-top: 5px;
}

.rowActions {
  text-align: right;
}

.rowActions a {
  display: inline-block;
  margin-right: 5px;
}

.formActions {
  margin-top: 5px;
}
.formActions .btn {
  min-width: 80px;
}

.page-heading {
  /*margin-bottom: 10px;*/
  border-bottom: 1px solid #e6e6e6;
}

@media (max-width: 767px) {
  .formActions {
    margin-top: 5px;
  }

  .formActions .btn {
    min-width: 0;
  }

  .page-content h1 {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 28px;
  }
}

.react-editable-field {
  display: block;
  width: 100%;

  line-height: 23px;
  color: #2c3e50;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #dce4ec;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

  height: 29px;
  padding: 3px 10px;
  font-size: 14px;
}


.user-packages .ReactTable .rt-tbody .rt-td {
  border: 0;
  padding: 5px;
}

.package-add-container {
  /*border-top: 1px solid #d3d3d3;
  margin-top: 30px;*/
}

.pagination-bottom {
  margin-top: 15px;
}

.react-datepicker-popper {
  z-index: 2
}

.form-control-static {
  padding-top: 9px;
  padding-bottom: 9px;
  margin-bottom: 0;
  min-height: 36px;
}

/*.ReactTable.my-apps-table .rt-tbody .rt-td {
    padding: 20px 8px
}*/

.page-loading.blocking {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.2);
  z-index: 9999;
  text-align: center;
  padding: 0;
  margin: 0;
}

.page-loading.blocking > span {
  position: absolute;
  top: 45%;
  background: #fff;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 0 5px 0 #555;
  left: calc(50% - 70px);
  /* right: 0; */
  width: 140px;
}

.purchase-box {
  background: #ecf0f1;
  padding: 15px 20px;
  border-radius: 6px;
}

.dashboard-panel .panel-body {
  padding: 0;
}

.dashboard-panel .panel-body table {
  margin-bottom: 0;
}