.content-filters {
  padding: 0 15px 15px;
  display: flex;
  align-items: center;

  .daterange-picker {
    width: 400px;
  }

  > div {
    padding: 0 30px 0 0;
    min-width: 300px;
  }
}

.content-cards {
  padding: 15px 15px 15px;

  .recording-row:last-child {
    margin-bottom: 0;
  }
}

.content-pagination {
  margin: 10px 15px;
  background: #fff;
  padding: 10px 15px;
  /* border-radius: 4px; */
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.generic-pagination {
  display: flex;
  justify-content: left;
  align-items: center;
}

.recording-row {
  display: flex;
  margin-bottom: 60px;
  /*max-width: 75%;*/

  .recording-card {

    flex-grow: 1;

    .card-body {
      padding: 0;
    }

    .card-content {

      display: flex;
    }
  }

  .recording-meta {
    /*margin-right: 10px;*/

    .meta-play,
    .meta-date {
      width: 100px;
      height: 100px;
      background: #fff;
      /* border-radius: 3px; */
      text-align: center;
      color: $primary;
      display: inline-block;
      float: left;
      line-height: 1;
      /*border: 1px solid $primary;*/
      border: 1px solid rgba(0, 0, 0, 0.125);
      margin: 1px 10px 10px 0;
      border-radius: 0.25rem;
      overflow: hidden;

      /*border: 1px solid $primary;
      color: $primary;*/
    }

    .meta-date {

    }

    .meta-day,
    .meta-month {
      font-size: 36px;
      display: block;
      padding-top: 20px;
      font-weight: 400;
    }

    .meta-month {
      font-size: 18px;
      padding-top: 7px;
      font-weight: 300;
      text-transform: uppercase;
    }

    .meta-info {
      width: 100px;
      height: 105px;


    }

    .meta-box {
      float: left;
      width: 47px;
      height: 49px;
      line-height: 1;
      border: 1px solid rgba(0, 0, 0, 0.125);
      margin: 0px 6px 8px 0;
      border-radius: 0.25rem;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      padding: 4px;
      color: #999;
      cursor: pointer;

      > span {
        font-size: 16px;
        font-family: "Open Sans";
        text-transform: uppercase;
        letter-spacing: -1px;
        font-weight: 500;
      }

      &.full-width {
        width: 100px;
        font-size: 14px;
        height: 36px;
      }

      &:nth-child(even) {
        margin-right: 0;
      }

      &:nth-child(3), &:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }

  .recording-visualiser {
    margin-right: 10px;
    display: flex;

    border-radius: 0.25rem;
    border: 1px solid #797979;

    position: relative;

    .play-button {
      position: absolute;
      color: #fff;
      font-size: 52px;
      display: flex;
      vertical-align: c;
      justify-content: center;
      align-items: center;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }

  .recording-main {
    flex-grow: 1;
    padding: 1.25rem;
  }


  .visualiser-canvas {
    /*position: fixed;
    left: 0;
    top: 0;*/
    width: 250px;
    height: 220px;
    fill: #797979;
    background: #797979;
  }

  .visualiser-audio {
    /*position: fixed;*/
    /*margin: 15px 10px;*/
    width: calc(100% - 20px);
    height: 35px;
  }

  .audio-element {
    margin: 25px 0 20px 0;
    position: relative;
  }

  .recording-labels {
    display: flex;
    /*margin: 15px 0 0;*/
    margin: 0;

    justify-content: flex-end;

    >div {
      margin-left: 25px;


      >i {

        margin-right: 5px;
      }
    }
  }

  /*.source-not-loaded {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.65);
    background: #fff;
    padding-top: 3px;
  }*/
  .source-not-loaded {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    > i {
      font-size: 2.4rem;
      /* display: flex; */
      margin-bottom: 15px;
      color: #868686;
    }

    > span {
      font-size: 14px;
      color: #868686;
    }
  }


  .recording-duration {
    display: inline-block;
    margin-right: 10px;
    color: #212529;
    font-weight: 600;
    font-family: "Roboto";
    /* font-family: "Open Sans"; */
    padding: 2px 6px;
    background: #eaeaea;
    border-radius: 3px;
  }
}

.video-row {
  .recording-card {
    flex-grow: 0;
    min-width: 640px;
  }
}


.waveform-container {
  position: relative;
  height: 140px;
  overflow: hidden;
  margin: 10px -1.25rem;
  background: #e6e6e6;
  padding: 0;
}

.waveform-audio-wave {
  padding: 10px 0;
}

.waveform-controls {
  /* width: 400px; */
  /*max-width: 400px;*/
  margin-bottom: 20px;

  .control-icon {
    width: 30px;

    font-size: 16px;

    &.icon-volume {
      font-size: 20px;
    }
  }

  .control-input {
    width: 200px;
    /*margin-right: 150px;*/
    padding: 6px 0 0;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.waveform-zoom {
  width: 100%;
}

.video-player {
  display: flex;
  justify-content: center;
}

.waveform-player-button,
.video-player-button {
  padding: 0;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 0;
  font-size: 20px;
  color: #ffffff;

  &:hover,
  &:active,
  &:disabled,
  &.disabled {
    color: #ffffff !important;
  }

  &.play {
    padding: 0 0 0 5px;
  }
}

.participants-table {
  max-width: 450px;

  .recording-duration {
    display: inline-block;
    margin-right: 10px;
    color: #212529;
    font-weight: 600;
    font-family: "Roboto";
    padding: 1px 6px;
    background: #eaeaea;
    border-radius: 3px;
  }

  .col-icon {
    width: 50px;
    text-align: center;
    padding-right: 10px;
    font-size: 16px;

    > i {
      font-size: 16px;
    }
  }
}


.waveform-regions-list {
  /*background-color: rgba(0, 0, 0, 0.18) !important;*/

  .recording-duration {
    display: inline-block;
    margin-right: 10px;
    color: #212529;
    font-weight: 600;
    font-family: "Roboto";
    padding: 1px 6px;
    background: #eaeaea;
    border-radius: 3px;

    width: 70px;
    text-align: center;
  }
}

.wavecontrol-regions {
  margin: 15px 0 30px;
}

.waveform-regions-list {
  margin: 20px 0px;

  .dropdown-toggle:after {
    /*display: none;*/
  }
}
