.broadcast-container {
    max-width: 860px;
    margin: 30px auto;

    .step-container {
        padding: 30px 20px;
        max-width: 620px;
        margin: 0 auto;
    }

    .recipient-type-btn-group {
        .btn {
            margin-right: 15px;
        }
    }

    .filter-regions {
        max-width: 600px;
        margin: 30px auto;
        /* text-align: center; */
    }

    .filter-users {
        max-width: 600px;
        margin: 30px auto;
        /* text-align: center; */
    }

    .step-actions {
        text-align: center;
        border-top: 1px solid #dee2e6 !important;
        padding-top: 15px;
    }

    .review-step {

    }

    .message-preview {
        color: #fff;
        border-radius: 4px;
        padding: 15px 20px;

        .m-title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 5px;
        }

        &.message-1 {
            background: #ADBBA2;
        }

        &.message-2 {
            background: #DDBA92;
        }

        &.message-3 {
            background: #DA7676;
        }
    }
}