.custom-container {
  max-width: 1200px;
  margin: auto;
}


.greyed-out {
  opacity: 0.5;
  pointer-events: none;
}
.prevent-clicks {
  pointer-events: none;
}
.cursor-pointer {
  cursor: pointer;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.wauto {
  width: auto;
}

.text-underline {
  text-decoration: underline;
}
.size12 {
  font-size: 12px !important;
}
.size13 {
  font-size: 13px !important;
}
.size14 {
  font-size: 14px !important;
}
.size15 {
  font-size: 15px !important;
}
.size16 {
  font-size: 16px !important;
}
.size18 {
  font-size: 18px !important;
}
.size20 {
  font-size: 20px !important;
}
.size30 {
  font-size: 30px !important;
}
.size40 {
  font-size: 40px !important;
}

.padtops {
  padding-top: 5px;
}
.innerLR {
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
}
.innerTB {
  padding-bottom: 25px;
  padding-top: 25px;
  position: relative;
}
.innerAll {
  padding: 25px;
  position: relative;
}
.innerL {
  padding-left: 25px;
  position: relative;
}
.innerR {
  padding-right: 25px;
  position: relative;
}
.innerT {
  padding-top: 25px;
  position: relative;
}
.innerB {
  padding-bottom: 25px;
  position: relative;
}
.center {
  text-align: center !important;
}
.height-full {
  height: 100%;
}
.margin-none {
  margin: 0 !important;
}
.margin-top-none {
  margin-top: 0 !important;
}
.margin-bottom-none {
  margin-bottom: 0 !important;
}
.margin-left-none {
  margin-left: 0 !important;
}
.margin-right-none {
  margin-right: 0 !important;
}
.padding-none {
  padding: 0 !important;
}
.padding-top-none {
  padding-top: 0 !important;
}
.padding-bottom-none {
  padding-bottom: 0 !important;
}
.padding-left-none {
  padding-left: 0 !important;
}
.padding-right-none {
  padding-right: 0 !important;
}
.padding-none-TB {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.padding-none-LR {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.border-none {
  border: medium none !important;
}
.border-top-none {
  border-top: medium none !important;
}
.border-bottom-none {
  border-bottom: medium none !important;
}
.border-left-none {
  border-left: medium none !important;
}
.border-right-none {
  border-right: medium none !important;
}
.box-shadow-none {
  box-shadow: none !important;
}

.relativeWrap {
  position: relative
}
.display-block {
  display: block
}
.display-block-inline {
  display: inline-block
}
.display-none {
  display: none
}
.block {
  display: block
}
.inline-block {
  display: inline-block
}
.text-white {
  color: #fff
}
.text-white-stopwatch {
  color: #fff;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
}
.text-faded {
  color: #d7d7d7 !important
}
.text-muted-dark {
  color: #a2a2a2
}
.text-muted-darker {
  color: #898989
}
.text-weight-regular {
  font-weight: normal !important
}
.text-weight-normal {
  font-weight: 400
}
.text-condensed {
  letter-spacing: -2px
}
.text-uppercase {
  text-transform: uppercase
}
.text-lowercase {
  text-transform: lowercase
}
.text-weight-bold {
  font-weight: bold
}
.text-weight-300 {
  font-weight: 300;
}
.text-weight-400 {
  font-weight: 400;
}
.text-weight-500 {
  font-weight: 500;
}
.text-weight-600 {
  font-weight: 600;
}
.text-muted {
  color: #999 !important
}
.text-right {
  text-align: right !important
}
.text-center {
  text-align: center !important
}
.text-small {
  font-size: 12px !important;
}
.text-larger {
  font-size: 14px
}
.text-larger-medium {
  font-size: 20px;
  /* line-height: 20px */
}
.text-medium {
  font-size: 30px;
  line-height: 30px
}
.text-large {
  font-size: 35px !important;
  line-height: 40px !important
}
.text-xlarge {
  font-size: 50px;
  line-height: 50px
}
.text-xxlarge {
  font-size: 70px;
  line-height: 70px
}
.text-underline {
  text-decoration: underline
}
.inline-block {
  display: inline-block !important
}
.overflow-y-auto {
  position: relative;
  overflow: hidden;
  overflow-y: auto
}
.overflow-hidden {
  position: relative;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.border-top {
  border-top: 1px solid #efefef
}
.border-right {
  border-right: 1px solid #efefef
}
.border-bottom {
  border-bottom: 1px solid #efefef !important
}
.border-left {
  border-left: 1px solid #efefef
}
.border-color-primary {
  border-color: #f77f00 !important
}
.half.innerAll {
  padding: 7.5px !important
}
.half.innerLR {
  padding-left: 7.5px !important;
  padding-right: 7.5px !important
}
.half.innerTB {
  padding-top: 7.5px !important;
  padding-bottom: 7.5px !important
}
.half.innerT {
  padding-top: 7.5px !important
}
.half.innerB {
  padding-bottom: 7.5px !important
}
.half.innerL {
  padding-left: 7.5px !important
}
.half.innerR {
  padding-right: 7.5px !important
}

.semi-half.innerAll {
  padding: 7.5px !important
}
.semi-half.innerLR {
  padding-left: 12.5px !important;
  padding-right: 12.5px !important
}
.semi-half.innerTB {
  padding-top: 12.5px !important;
  padding-bottom: 12.5px !important
}
.semi-half.innerT {
  padding-top: 12.5px !important
}
.semi-half.innerB {
  padding-bottom: 12.5px !important
}
.semi-half.innerL {
  padding-left: 12.5px !important
}
.semi-half.innerR {
  padding-right: 12.5px !important
}

.inner-2x.innerAll {
  padding: 30px !important
}
.inner-2x.innerTB {
  padding-top: 30px !important;
  padding-bottom: 30px !important
}
.inner-2x.innerLR {
  padding-left: 30px !important;
  padding-right: 30px !important
}
.inner-2x.padding-bottom-none {
  padding-bottom: 0 !important
}
.inner-2x.padding-right-none {
  padding-right: 0 !important
}

.marginLR {
  margin-left: 25px;
  margin-right: 25px;
  position: relative;
}
.marginTB {
  margin-bottom: 25px;
  margin-top: 25px;
  position: relative;
}
.marginAll {
  margin: 25px;
  position: relative;
}
.marginL {
  margin-left: 25px;
  position: relative;
}
.marginR {
  margin-right: 25px;
  position: relative;
}
.marginT {
  margin-top: 25px;
  position: relative;
}
.marginB {
  margin-bottom: 25px;
  position: relative;
}
.half.marginAll {
  margin: 7.5px !important
}
.half.marginLR {
  margin-left: 7.5px !important;
  margin-right: 7.5px !important
}
.half.marginTB {
  margin-top: 7.5px !important;
  margin-bottom: 7.5px !important
}
.half.marginT {
  margin-top: 7.5px !important
}
.half.marginB {
  margin-bottom: 7.5px !important
}
.half.marginL {
  margin-left: 7.5px !important
}
.half.marginR {
  margin-right: 7.5px !important
}

.semi-half.marginAll {
  margin: 7.5px !important
}
.semi-half.marginLR {
  margin-left: 12.5px !important;
  margin-right: 12.5px !important
}
.semi-half.marginTB {
  margin-top: 12.5px !important;
  margin-bottom: 12.5px !important
}
.semi-half.marginT {
  margin-top: 12.5px !important
}
.semi-half.marginB {
  margin-bottom: 12.5px !important
}
.semi-half.marginL {
  margin-left: 12.5px !important
}
.semi-half.marginR {
  margin-right: 12.5px !important
}

.margin-2x.marginAll {
  margin: 30px !important
}
.margin-2x.marginTB {
  margin-top: 30px !important;
  margin-bottom: 30px !important
}
.margin-2x.marginLR {
  margin-left: 30px !important;
  margin-right: 30px !important
}
.margin-2x.margin-bottom-none {
  margin-bottom: 0 !important
}
.margin-2x.margin-right-none {
  margin-right: 0 !important
}


.overflow-hidden {
  overflow: hidden !important
}
.overflow-x {
  overflow-x: auto
}
.unscrollable {
  overflow: hidden !important
}
.fixed-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0
}

.help-block {
  color: #7F7E7E;
  font-size: 14px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 0;
}

.row-merge {
  margin: 0 !important
}

.widget .row-merge {
  margin: 0
}
.row-merge {
  position: relative
}
.row-merge>[class*="col-"] {
  padding: 0
}
.row-merge>[class*="col-"]~[class*="col-"] {
  position: static
}
.row-merge>[class*="col-"]~[class*="col-"]:after {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  /* background: #e5e5e5; */
  content: ""
}
.row-merge.border-top,
.row-merge~.row-merge {
  border-top: 1px solid #e5e5e5
}
.row-merge.border-bottom {
  border-bottom: 1px solid #e5e5e5
}
.row-merge .fill-column:before {
  position: absolute;
  top: 0;
  bottom: 0;
  width: inherit;
  background: #f4f4f4;
  content: ""
}
@media (min-width: 768px) and (max-width: 991px) {
  .row-merge>[class*="col-sm-12"]~[class*="col-sm-12"] {
    position: relative
  }
  .row-merge>[class*="col-sm-12"]~[class*="col-sm-12"]:after {
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    right: 0;
    width: auto;
    height: 1px;
    background: #e5e5e5;
    content: ""
  }
}


.xlarge-modal {
  min-width: 90%;
}

.select-sm .Select-control {
  height: 30px;
}

.select-sm .Select--single>.Select-control .Select-value,
.select-sm .Select-placeholder {
  line-height: 30px;
}

.select-sm .Select-input {
  height: 28px;
}

.select-sm .Select-input > input {
  height: 28px;
}

/* Form input sizing */
.input-mini {
  width: 45px !important; }

.input-xsmall {
  width: 80px !important; }

.input-small {
  width: 145px !important; }

.input-medium {
  width: 240px !important; }

.input-large {
  width: 320px !important; }

.input-xlarge {
  width: 420px !important; }

@media (max-width: 768px) {
  /* 768px */
  .input-large {
    width: 250px !important; }
  .input-xlarge {
    width: 300px !important; } }

.input-inline {
  display: inline-block;
  width: auto;
  vertical-align: middle; }

.input-fixed {
  overflow: hidden; }

.input-fixed {
  overflow: hidden; }

.form-group .input-inline {
  margin-right: 5px; }

.call-icon {
    height: 100%;
  }

@media (max-width: 768px) {
  /* 768px */
  .input-lg {
    width: 250px !important; }
  .input-xlg {
    width: 300px !important; } }
