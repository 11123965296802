.panel-stats {

  > .panel-heading {
    font-weight: 600;
    font-size: 20px;
  }
}

.stats-daterange-btn {
  background: #fff;
  color: #333;
  border: 0;
  box-shadow: 0 0 3px 1px #dedede;
  -moz-box-shadow: 0 0 3px 1px #dedede;
  -wekbit-box-shadow: 0 0 3px 1px #dedede;

  &:hover, &:focus, &.focus, &:active, &.active {
    color: #ffffff;
    background-color: #1967be;
    border-color: #1862b5;
    outline: 0;
  }
}

.stats-filter {
  .select__control {
    border-radius: 0;
    outline: 0;
  }
}

.recharts-default-tooltip {
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
}